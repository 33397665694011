import React, {FC} from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "../../shared/SocialsList/SocialsList";
import Label from "../../components/Label/Label";
import Input from "../../shared/Input/Input";
import Textarea from "../../shared/Textarea/Textarea";
import moment from "moment";
import {useNavigate} from "react-router-dom";
import {jsPDF} from "jspdf";


export interface ReservationData {
    reservation_id: string;
    country_code: string;
    phone_number: string;
    gcc: string;
    name: string;
    email: string;
    reservation_time: string;
    reservation_date: string;
}

export interface PayPageProps {
    className?: string;
    data?: ReservationData;
    lang: boolean | true;
}

const info = [
    {
        title: "🗺 ADDRESS",
        desc: "Rabaa Al Adaweyah, Nasr City, Cairo Governorate.",
        arTitle: "🗺 العنوان",
        arDesc: "مسجد, ٧ شارع ابن هاني الأندلسي من شارع الطيران, خلف, رابعة العدوية، مدينة نصر، محافظة القاهرة",
    },
    {
        title: "💌 EMAIL",
        desc: "info@alhusseinclinics.com",
        arTitle: "💌 البريد الالكتروني",
        arDesc: "info@alhusseinclinics.com",
    },
    {
        title: "☎ PHONE",
        desc: "+2 01013599753",
        arTitle: "☎ الهاتف",
        arDesc: "01013599753 2+ ",
    },
];

const PayPage: FC<PayPageProps> = ({className = "", data, lang}) => {
    const navigate = useNavigate();
    const renderContent = () => {

        const error = () => {
            console.log("empty");
        };
        const createPDF = (date: any, time: any, name: any, Email: any): void => {

            const doc = new jsPDF({
                orientation: "portrait",
                unit: "in",
                format: "letter"
            });
            // text is placed using x, y coordinates
            doc.setFontSize(18).text("Congratulations !!", 0.5, 1.0).setFont('bold');
            // create a line under heading
            doc.setLineWidth(0.03).line(0.5, 1.1, 8.0, 1.1).setLineHeightFactor(0.05);

            doc.setFontSize(18).text(`Name:                   ${name}`, 0.5, 2.0).setFont('bold');
            doc.setLineWidth(0.008).line(0.5, 2.1, 8.0, 2.1).setLineHeightFactor(0.05);
            doc.setFontSize(18).text(`Reservation Date:       ${new Date(date).toDateString()}`, 0.5, 3.0).setFont('bold');
            doc.setLineWidth(0.008).line(0.5, 3.1, 8.0, 3.1).setLineHeightFactor(0.05);
            doc.setFontSize(18).text(`Reservation Time:       ${moment(time, "hh:mm:ss").format("hh:mm. A")}`, 0.5, 4.0).setFont('bold');
            doc.setLineWidth(0.008).line(0.5, 4.1, 8.0, 4.1).setLineHeightFactor(0.05);
            doc.setFontSize(18).text(`Email:                  ${Email}`, 0.5, 5.0).setFont('bold');
            doc.setLineWidth(0.008).line(0.5, 5.1, 8.0, 5.1).setLineHeightFactor(0.05);
            doc.setFontSize(18).text(`Thank you for choosing us for your safety.`, 0.5, 6.5).setFont('bold');
            // Creating footer and saving file
            doc.save(`${name}_Reservation.pdf`);
        };

        return (
            <div id="pdf"
                 className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-8 px-0 sm:p-6 xl:p-8">
                <h2 className="text-3xl lg:text-4xl font-semibold">
                    {
                        lang
                            ?
                            "Congratulation 🎉"
                            :
                            "تهانينا 🎉"
                    }
                </h2>

                <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
                <span>
                    {
                        lang
                            ?
                            "A confirmation E-mail had been sent to your inbox."
                            :
                            "تم ارسال رسالة تأكيد الي البريد الالكتروني."
                    }
                </span>
                {/* ------------------------ */}
                <div className="space-y-6">
                    <h3 className="text-2xl font-semibold">{
                        lang
                            ?
                            "Your reservation"
                            :
                            "تفاصيل الحجز"
                    }</h3>
                    <div
                        className="mt-6 border border-neutral-200 dark:border-neutral-700 rounded-3xl flex flex-col sm:flex-row divide-y sm:divide-x sm:divide-y-0 divide-neutral-200 dark:divide-neutral-700">
                        <div className="flex-1 p-5 flex space-x-4">
                            <svg
                                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.33333 8.16667V3.5M18.6667 8.16667V3.5M8.16667 12.8333H19.8333M5.83333 24.5H22.1667C23.4553 24.5 24.5 23.4553 24.5 22.1667V8.16667C24.5 6.878 23.4553 5.83333 22.1667 5.83333H5.83333C4.54467 5.83333 3.5 6.878 3.5 8.16667V22.1667C3.5 23.4553 4.54467 24.5 5.83333 24.5Z"
                                    stroke="#D1D5DB"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>

                            <div className="flex flex-col">
                                <span className="text-sm text-neutral-400">{
                                    lang
                                        ?
                                        "Date"
                                        :
                                        "التاريخ"
                                }</span>
                                <span className="mt-1.5 text-lg font-semibold">
                  {
                      data
                          ?
                          new Date(data.reservation_date).toDateString()
                          :
                          null
                  }
                    </span>
                            </div>
                        </div>
                        <div className="flex-1 p-5 flex space-x-4">
                            <svg
                                className="w-8 h-8 text-neutral-300 dark:text-neutral-6000"
                                viewBox="0 0 28 28"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M14 5.07987C14.8551 4.11105 16.1062 3.5 17.5 3.5C20.0773 3.5 22.1667 5.58934 22.1667 8.16667C22.1667 10.744 20.0773 12.8333 17.5 12.8333C16.1062 12.8333 14.8551 12.2223 14 11.2535M17.5 24.5H3.5V23.3333C3.5 19.4673 6.63401 16.3333 10.5 16.3333C14.366 16.3333 17.5 19.4673 17.5 23.3333V24.5ZM17.5 24.5H24.5V23.3333C24.5 19.4673 21.366 16.3333 17.5 16.3333C16.225 16.3333 15.0296 16.6742 14 17.2698M15.1667 8.16667C15.1667 10.744 13.0773 12.8333 10.5 12.8333C7.92267 12.8333 5.83333 10.744 5.83333 8.16667C5.83333 5.58934 7.92267 3.5 10.5 3.5C13.0773 3.5 15.1667 5.58934 15.1667 8.16667Z"
                                    stroke="#D1D5DB"
                                    strokeWidth="1.5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                />
                            </svg>

                            <div className="flex flex-col">
                                <span className="text-sm text-neutral-400">{
                                    lang
                                        ?
                                        "Time"
                                        :
                                        "الوقت"
                                }</span>
                                <span className="mt-1.5 text-lg font-semibold">
                                    {
                                        data
                                            ?
                                            moment(data.reservation_time, "hh:mm:ss").format("hh:mm. A")
                                            :
                                            null
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                {/* ------------------------ */}
                <div className="space-y-6">
                    <h3 className="text-2xl font-semibold">{
                        lang
                            ?
                            "Reservation details"
                            :
                            "بيانات الحجز"
                    }</h3>
                    <div className="flex flex-col space-y-4">
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">{
                                lang
                                    ?
                                    "Name"
                                    :
                                    "الاسم"
                            }</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                {
                                    data
                                        ?
                                        data.name
                                        :
                                        null
                                }
                            </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">{
                                lang
                                    ?
                                    "Email"
                                    :
                                    "البريد الالكتروني"
                            }</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                {
                                    data
                                        ?
                                        data.email
                                        :
                                        null
                                }
                            </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">{
                                lang
                                    ?
                                    "Date"
                                    :
                                    "التاريخ"
                            }</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                {
                                    data
                                        ?
                                        new Date(data.reservation_date).toDateString()
                                        :
                                        null
                                }
                            </span>
                        </div>
                        <div className="flex text-neutral-6000 dark:text-neutral-300">
                            <span className="flex-1">{
                                lang
                                    ?
                                    "Time"
                                    :
                                    "الوقت"
                            }</span>
                            <span className="flex-1 font-medium text-neutral-900 dark:text-neutral-100">
                                {
                                    data
                                        ?
                                        moment(data.reservation_time, "hh:mm:ss").format("hh:mm. A")
                                        :
                                        null
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div className="flex items-center justify-between">

                    <ButtonPrimary
                        onClick={() => {
                            navigate(0)
                        }}
                    >{
                        lang
                            ?
                            "Go back"
                            :
                            "العودة الي الخلف"
                    }</ButtonPrimary>

                    <ButtonPrimary
                        onClick={data ? () => {
                                createPDF(data.reservation_date, data.reservation_time, data.name, data.email)
                            }
                            :
                            () => {
                                error()
                            }
                        }
                        // onClick={() => navigate(`/print?gcc=${data?.reservation_id}&reservation_date=${data?.reservation_date}&reservation_time=${data?.reservation_time}`)}
                    >{
                        lang
                            ?
                            "Print as PDF"
                            :
                            "طباعة"
                    }</ButtonPrimary>

                </div>
            </div>
        );
    };

    return (
        <div dir={lang ? "ltr" : "rtl"} className={`nc-PayPage ${className}`} data-nc-id="PayPage">
            <main className="container mt-11 mb-24 lg:mb-32 ">
                <div className="max-w-4xl mx-auto">{renderContent()}</div>
                {/* Contact Us */}
                <div className="mb-24 lg:mb-32">
                    <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        {
                            lang
                                ?
                                "Contact Us"
                                :
                                "تواصل معنا"
                        }
                    </h2>
                    <div className="container max-w-7xl mx-auto">
                        <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
                            <div className="max-w-sm space-y-8">
                                {info.map((item, index) => (
                                    <div key={index}>
                                        <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                                            {
                                                lang
                                                    ?
                                                    item.title
                                                    :
                                                    item.arTitle
                                            }
                                        </h3>
                                        <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                                    {
                                                        lang
                                                            ?
                                                            item.desc
                                                            :
                                                            item.arDesc
                                                    }
                                                </span>
                                    </div>
                                ))}
                            </div>
                            <div>
                                <form className="grid grid-cols-1 gap-6" action="#" method="post">
                                    <label className="block">
                                        <Label>
                                            {
                                                lang
                                                    ?
                                                    "Full name"
                                                    :
                                                    "الاسم"
                                            }
                                        </Label>

                                        <Input
                                            placeholder={
                                                lang
                                                    ?
                                                    "Example Doe"
                                                    :
                                                    "الاسم"
                                            }
                                            type="text"
                                            className="mt-1"
                                        />
                                    </label>
                                    <label className="block">
                                        <Label>
                                            {
                                                lang
                                                    ?
                                                    "Email address"
                                                    :
                                                    "البريد الالكتروني"
                                            }
                                        </Label>

                                        <Input
                                            type="email"
                                            placeholder={
                                                lang
                                                    ?
                                                    "example@example.com"
                                                    :
                                                    "البريد الالكتروني"
                                            }
                                            className="mt-1"
                                        />
                                    </label>
                                    <label className="block">
                                        <Label>
                                            {
                                                lang
                                                    ?
                                                    "Message"
                                                    :
                                                    "الرسالة"
                                            }
                                        </Label>

                                        <Textarea className="mt-1" rows={6}/>
                                    </label>
                                    <div>
                                        <ButtonPrimary type="submit">{
                                            lang
                                                ?
                                                "Send Message"
                                                :
                                                "ارسال"
                                        }</ButtonPrimary>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default PayPage;
